.rotate-demo {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.rotate-demo > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.rotate-demo > .content .logo-wrap svg {
  transform: scale(0.5);
}
