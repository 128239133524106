.list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    gap: 5px;
    text-align: left;
    color: black;
    border-bottom: 1px solid #efefef;
    text-transform: none;
    width: 100%;
    height: 80px;
}
.list-item .description {
    color: grey;
    line-height: 15px;
}