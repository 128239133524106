.compound-demo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.compound-demo .content {
  overflow: hidden;
}