@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  touch-action: none;
  user-select: none;
  font-family: 'Roboto', sans-serif;
}
body.touch-auto .screen {
  touch-action: auto;
}
html,
body,
header {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}