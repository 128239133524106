.navbar {
  height: 64px;
  background-color: #5A0FC8;
  color: white;
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px black;
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 1000;
}
.navbar .MuiButtonBase-root {
  color: white;
}
.navbar .back {
  position: absolute;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
